import { PAGE_TYPE_CART } from 'shared/consts'
import {
  CART_CLICK_ON_MAIN_CTA_BUTTON,
  CART_CLICK_ON_STICKY_CTA_BUTTON,
  CART_CLICK_ON_TOP_CTA_BUTTON,
  CART_STICKY_CTA_EXPERIMENT,
  EXPERIMENT_VARIATION,
} from 'shared/experiments/consts'
import {
  activateExperiment,
  getExperimentOnClient,
} from 'shared/experiments/utils/experiments'
import { getExperimentNamePerTenant } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'
import store from 'shared/store'
import { SPA_CART } from 'views/consts'

export default () => {
  const { publicRuntimeConfig } = store.getState()
  const cart = store.getState().cart.data
  const { tenant, deviceClass } = publicRuntimeConfig?.publicConfig || {}
  const { pageType } = publicRuntimeConfig.pageProperties || {}
  const experimentName = getExperimentNamePerTenant(
    CART_STICKY_CTA_EXPERIMENT,
    tenant,
    deviceClass
  )

  const { isEnabled, variant } = getExperimentOnClient(experimentName)
  if (!isEnabled || pageType !== PAGE_TYPE_CART || !cart.sellerGroups) {
    return
  }
  const cartStickyCta = document.querySelector<HTMLElement>(
    '[data-clientside-hook="sticky-cta"]'
  )

  const stickyBanner = document.querySelector<HTMLElement>(
    '[data-clientside-hook~="stickyBanner"]'
  )

  const showStickyCta = () => {
    if (cartStickyCta) {
      cartStickyCta.classList.remove('u-hidden')
      document.body.classList.add('withNlBanner')
    }

    if (stickyBanner) {
      stickyBanner.classList.add('u-hidden')
    }
  }

  const hideStickyCta = () => {
    if (cartStickyCta) {
      cartStickyCta.classList.add('u-hidden')
      document.body.classList.remove('withNlBanner')
    }
    if (stickyBanner) {
      stickyBanner.classList.remove('u-hidden')
    }
  }

  const spaCart = document.getElementById(SPA_CART)
  if (spaCart && variant !== EXPERIMENT_VARIATION.DEFAULT) {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        hideStickyCta()
      } else {
        showStickyCta()
      }
    })
    observer.observe(spaCart)
  }
  const activateCartStickyCtaExperiment = () => {
    if (window.scrollY > 420) {
      const isExperimentActivated =
        window.sessionStorage.getItem(experimentName)

      if (!isExperimentActivated) {
        window.sessionStorage.setItem(experimentName, 'true')
        void activateExperiment(experimentName)
      }
    }
  }
  activateCartStickyCtaExperiment()
  window.addEventListener('scroll', activateCartStickyCtaExperiment)

  document.getElementById(SPA_CART)?.addEventListener('click', event => {
    const element = <HTMLElement>event.target

    if (element.closest('#ToCheckoutTop')) {
      trackOptimizelyEvent(CART_CLICK_ON_TOP_CTA_BUTTON)
    }
    if (element.closest('.o-stickyCta #ToCheckout')) {
      trackOptimizelyEvent(CART_CLICK_ON_STICKY_CTA_BUTTON)
    }
    if (element.closest('.o-CartSumBox  #ToCheckout')) {
      trackOptimizelyEvent(CART_CLICK_ON_MAIN_CTA_BUTTON)
    }
  })
}
